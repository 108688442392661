import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FormRegisterCompany from "../components/forms/formRegisterCompany"
import FormRegisterCustomer from "../components/forms/formRegisterCustomer"
import FormRegisterDealer from "../components/forms/formRegisterDealer"

const IndexPage = () => {
  const [modal, setModal] = useState(false)

  const [modalRegisterCustomer, setModalRegisterCustomer] = useState(false)
  const [
    modalRegisterTransportCompany,
    setModalRegisterTransportCompany,
  ] = useState(false)
  const [modalRegisterCarDealer, setModalRegisterCarDealer] = useState(false)

  const resetModal = () => {
    setModal(false)
    setModalRegisterCustomer(false)
    setModalRegisterTransportCompany(false)
    setModalRegisterCarDealer(false)
  }

  return (
    <Layout>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SEO title="Home" />

      <section className="home-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <h1>
                  Car transport has never been <span>easier</span>
                </h1>
                <div className="home-top__icons">
                  <div>
                    <img
                      className="img-fluid"
                      src={require("../assets/images/home-top-ico-1.svg")}
                      alt=""
                    />
                    <p>
                      Save <br />
                      on fee
                    </p>
                  </div>
                  <div>
                    <img
                      className="img-fluid"
                      src={require("../assets/images/home-top-ico-2.svg")}
                      alt=""
                    />
                    <p>
                      Unlimited number <br />
                      of quotes <br />
                      every month
                    </p>
                  </div>
                  <div>
                    <img
                      className="img-fluid"
                      src={require("../assets/images/home-top-ico-3.svg")}
                      alt=""
                    />
                    <p>
                      Only genuine <br />
                      transport <br />
                      companies in UK
                    </p>
                  </div>
                </div>
                <button
                  onClick={e => setModal(true)}
                  className="btn-custom btn-custom--green"
                >
                  Register now
                </button>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <img
                className="img-fluid img-main"
                src={require("../assets/images/global-phone.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="home-pricing">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <img
                className="img-fluid"
                src={require("../assets/images/home-pricing-image.svg")}
                alt=""
              />
            </div>
            <div className="col-lg-5">
              <h2>Are you looking for new customers?</h2>
              <p>We can help you grow your business!</p>
              <div className="pricing-tile">
                <div className="pricing-tile-header">Basic package</div>
                <div className="pricing-tile-price">
                  <sup>£</sup>99<small>+VAT</small>
                </div>
                <div className="pricing-tile-label">per month</div>
                <Link
                  to="/transport-company/"
                  className="btn-custom btn-custom--block btn-custom--green"
                >
                  Find out more
                </Link>
                <div className="pricing-tile-info">
                  This offer is available for <strong>limited time</strong> only
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-info">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="home-info__tile">
                <img
                  className="img-fluid"
                  src={require("../assets/images/home-top-ico-2.svg")}
                  alt=""
                />
                <p>
                  <strong>Unlimited number</strong>
                  <br /> of new jobs every month
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-info__tile home-info__tile--primary">
                <img
                  className="img-fluid"
                  src={require("../assets/images/home-top-ico-1-white.svg")}
                  alt=""
                />
                <p>
                  <strong>
                    Save <br />
                    on fee
                  </strong>
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="home-info__tile">
                <img
                  className="img-fluid"
                  src={require("../assets/images/home-top-ico-3.svg")}
                  alt=""
                />
                <p>
                  Only <strong>genuine</strong> transport <br />
                  <strong>companies</strong> in UK
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-transport">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <h2>Are you looking for genuine transport company?</h2>
              <ul className="custom-list custom-list--check">
                <li>Save time and money</li>
                <li>With us your car is safe</li>
                <li>
                  We are fast growing company who offers the best vehicle
                  delivery service in UK
                </li>
                <li>Unlimited quotes</li>
              </ul>
              <Link to="/customer/" className="btn-custom btn-custom--green">
                Check out more
              </Link>
            </div>
            <div className="col-md-7">
              <img
                className="img-fluid"
                src={require("../assets/images/home-transport-image.svg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {modal && (
        <>
          <div className="custom-modal">
            {modalRegisterCustomer === true ? (
              <FormRegisterCustomer />
            ) : modalRegisterTransportCompany === true ? (
              <FormRegisterCompany />
            ) : modalRegisterCarDealer === true ? (
              <FormRegisterDealer />
            ) : (
              <>
                <h3>Continue as</h3>
                <div className="row justify-content-center">
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterCustomer(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-customer.svg")}
                          alt=""
                        />
                      </div>
                      <p>Customer</p>
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterTransportCompany(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-transport-company.svg")}
                          alt=""
                        />
                      </div>
                      <p>
                        Transport <br /> Company
                      </p>
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterCarDealer(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-car-dealer.svg")}
                          alt=""
                        />
                      </div>
                      <p>
                        Car <br /> Dealer
                      </p>
                    </button>
                  </div>
                </div>
              </>
            )}
            <button
              className="custom-modal__close"
              onClick={resetModal}
            ></button>
          </div>
          <button
            className="custom-modal__overlay"
            onClick={resetModal}
          ></button>
        </>
      )}
    </Layout>
  )
}

export default IndexPage
